
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import { getMemberLearningView } from "@/services/v1/member/learning";
import { LearningMateri } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    // START === setup breadcrumb =====================================================
    const route = useRoute();
    const materiVideoID = computed(() => route.params?.slug);

    const pathMemberMateri = `/member/materi`;
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Materi Pembelajaran",
        to: pathMemberMateri,
      },
      {
        title: `Materi Video`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    // START === get materi video detail ================================================================
    const {
      data: dataMateriVideo,
      pending: pendingMateriVideo,
      error: errorMateriVideo,
      execute: executeMateriVideo,
    } = useWiseFetch<BaseResponse<LearningMateri>>(() => {
      return getMemberLearningView(materiVideoID.value as string);
    });

    const prettierMateriVideo = computed(() => {
      return dataMateriVideo.value?.response;
    });
    const heightVideo = ref(100);

    const frameVideo = ref();
    const isLoadedVideo = ref(false);

    const setLoadingVideo = () => {
      frameVideo.value.onload = () => {
        isLoadedVideo.value = true;
      };
    };

    watch(dataMateriVideo, () => {
      setLoadingVideo();
    });

    const setupHeightVideo = () => {
      const heightContent = document.getElementsByClassName("content")[0]
        .clientHeight;

      heightVideo.value = heightContent - 210;
    };

    watch(isLoadedVideo, (newVal) => {
      if (newVal) setupHeightVideo();
    });

    watch(
      materiVideoID,
      (newVal) => {
        if (newVal) {
          console.log("materiVideoID ", newVal);
          executeMateriVideo();
        }
      },
      { immediate: true }
    );

    // END === get materi video detail ==================================================================

    return {
      heightVideo,
      isLoadedVideo,
      frameVideo,
      prettierMateriVideo,
    };
  },
});
