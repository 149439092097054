import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { class: "intro-y flex items-center justify-between h-10 my-5" }
const _hoisted_3 = { class: "text-lg font-medium truncate mr-5" }
const _hoisted_4 = {
  class: "h-full w-full flex flex-col",
  style: {"height":"'1000px'"}
}
const _hoisted_5 = ["height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingDiv = _resolveComponent("LoadingDiv")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, " Materi Video " + _toDisplayString(`"${_ctx.prettierMateriVideo?.title}"`), 1)
    ]),
    (!_ctx.isLoadedVideo)
      ? (_openBlock(), _createBlock(_component_LoadingDiv, {
          key: 0,
          class: "mt-5"
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("iframe", {
        class: "w-full h-full",
        height: _ctx.heightVideo,
        src: _ctx.prettierMateriVideo?.content,
        frameborder: "0",
        ref: "frameVideo"
      }, null, 8, _hoisted_5)
    ], 512), [
      [_vShow, _ctx.isLoadedVideo]
    ])
  ]))
}